import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ReferralForm from '../components/ReferralForm';
import IOSInstallPrompt from '../components/IOSInstallPrompt';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { installButton: null };
  }

  installPrompt = null;

  componentDidMount() {
    console.log('Listening for Install prompt');
    window.addEventListener('beforeinstallprompt', e => {
      // For older browsers
      e.preventDefault();
      console.log('Install Prompt fired');
      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if (
        (window.matchMedia &&
          window.matchMedia('(display-mode: standalone)').matches) ||
        window.navigator.standalone === true
      ) {
        return false;
      }
      // Set the state variable to make button visible
      this.setState({
        installButton: true,
      });
    });
  }

  installApp = async () => {
    if (!this.installPrompt) {
      return false;
    }
    this.installPrompt.prompt();
    let outcome = await this.installPrompt.userChoice;
    if (outcome.outcome == 'accepted') {
      console.log('App Installed');
    } else {
      console.log('App not installed');
    }
    // Remove the event reference
    this.installPrompt = null;
    // Hide the button
    this.setState({
      installButton: false,
    });
  };

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <div className="container">
          <div className="grid justify-center">
            <div className="col-12 col-md-8 col-xlg-6">
              <h1>FLOWS referral</h1>
              <p className="u-margin-top-full u-font-bold u-font-normal u-text-blue-800">
                This form is for front line agencies, and for statutory agencies
                such as the police or social services. Use this form to refer an
                individual to the FLOWS team. The FLOWS team can provide legal
                advice about a domestic abuse situation.
              </p>
              <p className="u-margin-top-full">
                Please complete these details for the person you are referring.
                The FLOWS team will contact them on the next working day.
              </p>
              <p className="u-margin-top-full">
                If you are a woman and need our help you can refer yourself
                directly to FLOWS using our{' '}
                <a href="https://self-referral.flows.org.uk">
                  self referral form
                </a>
                .
              </p>
              <ReferralForm />
              <p className="u-margin-top-full" aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  className="svg-icon red"
                >
                  <path d="M110.1 16.4L75.8 56.8l.3 1 50.6-10.2v32.2l-50.9-8.9-.3 1 34.7 39.1-28.3 16.5-18.2-49.3-.7.3-18.5 49L17.2 111l34.1-39.8v-.6l-50 9.2V47.6l49.3 9.9.3-.6-33.7-40.2L45.5.5l17.8 48.7h.7L82.1.5l28 15.9z" />
                </svg>
                &nbsp;— required field
              </p>
            </div>
          </div>
        </div>
        {this.state.installButton === true && (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              padding: '4px',
              width: '100%',
              backgroundColor: '#fff',
              zIndex: 100,
              boxShadow: `1px 3px 5px rgba(0, 0, 0, 0.5)`,
              textAlign: 'center',
            }}
          >
            <button onClick={this.installApp}>Add to home screen</button>
          </div>
        )}
        <IOSInstallPrompt />
      </Layout>
    );
  }
}

export default IndexPage;
