import React from 'react';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { navigate } from 'gatsby';

import InputText from './InputText';
import InputTextarea from './InputTextarea';

import RequiredAsterisk from './RequiredAsterisk';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ReferralForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {};

    this.recaptchaRef = React.createRef();
  }

  render() {
    return (
      <>
        <Formik
          initialValues={{
            clientName: '',
            dateOfBirth: '',
            dayOfBirth: '',
            monthOfBirth: '',
            yearOfBirth: '',
            postcode: '',
            clientPhoneNumber: '',
            clientEmail: '',
            contactHours: '',
            respondentName: '',
            respondentDateOfBirth: '',
            respondentDayOfBirth: '',
            respondentMonthOfBirth: '',
            respondentYearOfBirth: '',
            otherDetails: '',
            consent: false,
            organisation: '',
            referrerName: '',
            referrerEmail: '',
            referrerPhoneNumber: '',
            recaptcha: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // Concatenate date fields
            values.dateOfBirth = `${values.dayOfBirth}/${values.monthOfBirth}/${
              values.yearOfBirth
            }`;

            values.respondentDateOfBirth = `${values.respondentDayOfBirth}/${
              values.respondentMonthOfBirth
            }/${values.respondentYearOfBirth}`;

            fetch(`${process.env.GATSBY_FUNCTIONS_URL}/form-submitted`, {
              method: 'POST',
              mode: 'no-cors',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({ 'form-name': 'Referral form', ...values }),
            })
              // If submission is successful
              // @NOTE fetch API only rejects a promise when a “network error is
              // encountered, although this usually means permissions issues or
              // similar.” — use response.ok to actually check status
              .then(response => {
                setSubmitting(false);
                this.recaptchaRef.current.reset();
                if (!response.ok) {
                  response.json().then(data => {
                    if ('message' in data) {
                      alert(data.message);
                    }
                  });
                } else {
                  resetForm();
                  navigate('/success/');
                }
              })
              .catch(error => alert(error));
          }}
          validationSchema={Yup.object().shape({
            clientName: Yup.string().required('Enter the client’s name'),
            dateOfBirth: Yup.string(),
            dayOfBirth: Yup.number()
              .min(1, 'Must be a valid day')
              .max(31, 'Must be a valid day')
              .typeError('Must be a valid day'),
            monthOfBirth: Yup.number()
              .min(1, 'Must be a valid month')
              .max(12, 'Must be a valid month')
              .typeError('Must be a valid month'),
            yearOfBirth: Yup.number()
              .min(1900, 'Must be a valid year')
              .max(new Date().getFullYear() - 1, 'Must be a year in the past')
              .typeError('Must be a valid year'),
            postcode: Yup.string().required('Enter the client’s postcode'),
            clientPhoneNumber: Yup.string().required(
              'Enter the client’s phone number'
            ),
            clientEmail: Yup.string().email(),
            contactHours: Yup.string().required(
              'Enter the times when it is okay to contact the client'
            ),
            respondentName: Yup.string(),
            respondentDateOfBirth: Yup.string(),
            respondentDayOfBirth: Yup.number()
              .min(1, 'Must be a valid day')
              .max(31, 'Must be a valid day')
              .typeError('Must be a valid day'),
            respondentMonthOfBirth: Yup.number()
              .min(1, 'Must be a valid month')
              .max(12, 'Must be a valid month')
              .typeError('Must be a valid month'),
            respondentYearOfBirth: Yup.number()
              .min(1900, 'Must be a valid year')
              .max(new Date().getFullYear() - 1, 'Must be a year in the past')
              .typeError('Must be a valid year'),
            otherDetails: Yup.string().required(
              "Please provide some details about the client's situation"
            ),
            consent: Yup.boolean()
              .oneOf(
                [true],
                'You must confirm this person has consented to a FLOWS referral being completed on their behalf'
              )
              .required(),
            organisation: Yup.string().required(
              'Enter the name of your organisation'
            ),
            referrerName: Yup.string().required('Enter your name'),
            referrerEmail: Yup.string().email(),
            referrerPhoneNumber: Yup.string(),
            recaptcha: Yup.string().required(
              'Please tick the box to verify your submission '
            ),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;
            return (
              <form
                onSubmit={handleSubmit}
                name="Referral form"
                method="post"
                action={`${process.env.GATSBY_FUNCTIONS_URL}/form-submitted`}
                ref={this.formRef}
              >
                <input type="hidden" name="form-name" value="Referral form" />

                {/* Client’s name */}
                <InputText
                  errors={errors.clientName}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="clientName"
                  label="Client’s name"
                  name="clientName"
                  touched={touched.clientName}
                  value={values.clientName}
                  required={true}
                />

                {/* Client DoB */}
                <fieldset className="fieldset u-clearfix">
                  <legend>Client's date of birth</legend>
                  <p className="hint-text">
                    DD/MM/YYYY
                    <br />
                    It’s very helpful for us to have the client’s date of birth
                    so we can carry out a conflict of interest check before we
                    contact them.
                  </p>
                  <div className="fieldset__flex-container">
                    <InputText
                      errors={errors.dayOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="dayOfBirth"
                      label="Day"
                      name="dayOfBirth"
                      touched={touched.dayOfBirth}
                      value={values.dayOfBirth}
                      customClass="fieldset__input"
                    />
                    <InputText
                      errors={errors.monthOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="monthOfBirth"
                      label="Month"
                      name="monthOfBirth"
                      touched={touched.monthOfBirth}
                      value={values.monthOfBirth}
                      customClass="fieldset__input"
                    />
                    <InputText
                      errors={errors.yearOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="yearOfBirth"
                      label="Year"
                      name="yearOfBirth"
                      touched={touched.yearOfBirth}
                      value={values.yearOfBirth}
                      customClass="fieldset__input"
                    />
                  </div>
                </fieldset>

                {/* Postcode */}
                <InputText
                  errors={errors.postcode}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="postcode"
                  label="Postcode"
                  name="postcode"
                  touched={touched.postcode}
                  value={values.postcode}
                  required={true}
                />

                {/* Client: Phone number */}
                <InputText
                  errors={errors.clientPhoneNumber}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="clientPhoneNumber"
                  label="Phone number"
                  name="clientPhoneNumber"
                  touched={touched.clientPhoneNumber}
                  value={values.clientPhoneNumber}
                  required={true}
                />

                {/* Client: Email address */}
                <InputText
                  errors={errors.clientEmail}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="clientEmail"
                  label="Email address"
                  name="clientEmail"
                  touched={touched.clientEmail}
                  value={values.clientEmail}
                />

                {/* Contact hours */}
                <InputText
                  errors={errors.contactHours}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hintText="E.g. Any time, after 9:30am only."
                  id="contactHours"
                  label="When is it okay to contact the client by phone?"
                  name="contactHours"
                  touched={touched.contactHours}
                  value={values.contactHours}
                  required={true}
                />

                {/* Name of respondent */}
                <InputText
                  errors={errors.respondentName}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hintText="It’s very helpful for us to have the respondent’s name and date of birth so we can carry out a conflict of interest check before we contact the client. All details remain entirely confidential and the respondent will never be contacted."
                  id="respondentName"
                  label="Respondent's name"
                  name="respondentName"
                  touched={touched.respondentName}
                  value={values.respondentName}
                />

                {/* Respondent DoB */}
                <fieldset className="fieldset u-clearfix">
                  <legend>Respondent's date of birth</legend>
                  <p className="hint-text">DD/MM/YYYY</p>
                  <div className="fieldset__flex-container">
                    <InputText
                      errors={errors.respondentDayOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="respondentDayOfBirth"
                      label="Day"
                      name="respondentDayOfBirth"
                      touched={touched.respondentDayOfBirth}
                      value={values.respondentDayOfBirth}
                      customClass="fieldset__input fieldset__input-day"
                    />
                    <InputText
                      errors={errors.respondentMonthOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="respondentMonthOfBirth"
                      label="Month"
                      name="respondentMonthOfBirth"
                      touched={touched.respondentMonthOfBirth}
                      value={values.respondentMonthOfBirth}
                      customClass="fieldset__input fieldset__input-month"
                    />
                    <InputText
                      errors={errors.respondentYearOfBirth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      id="respondentYearOfBirth"
                      label="Year"
                      name="respondentYearOfBirth"
                      touched={touched.respondentYearOfBirth}
                      value={values.respondentYearOfBirth}
                      customClass="fieldset__input fieldset__input-year"
                    />
                  </div>
                </fieldset>

                {/* Other details */}
                <InputTextarea
                  errors={errors.otherDetails}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hintText="Please provide any information that may help us when we
                contact the client."
                  id="otherDetails"
                  label="Any other details about the client or their situation"
                  name="otherDetails"
                  touched={touched.otherDetails}
                  value={values.otherDetails}
                  rows={4}
                  required={true}
                />

                {/* Consent checkbox */}
                <div
                  className={`field-group ${
                    errors.consent && touched.consent ? 'error' : ''
                  }`}
                >
                  {errors.consent && touched.consent && (
                    <div className="validation-error">{errors.consent}</div>
                  )}
                  <label htmlFor="consent">
                    <input
                      name="consent"
                      id="consent"
                      type="checkbox"
                      checked={values.consent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.consent && touched.consent ? 'error' : ''
                      }
                    />
                    I confirm this person has consented to a FLOWS referral
                    being completed on their behalf
                    <RequiredAsterisk />
                  </label>
                </div>

                <p className="u-margin-top-full u-padding-top-full u-border-t">
                  Please give us a little more information about this referral.
                  We need to know who you are for monitoring purposes.
                </p>

                {/* Referrer: Organisation */}
                <InputText
                  errors={errors.organisation}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="organisation"
                  label="Your organisation"
                  name="organisation"
                  touched={touched.organisation}
                  value={values.organisation}
                  required={true}
                />

                {/* Referrer: Name */}
                <InputText
                  errors={errors.referrerName}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="referrerName"
                  label="Your name"
                  name="referrerName"
                  touched={touched.referrerName}
                  value={values.referrerName}
                  required={true}
                />

                <p className="u-margin-top-full">
                  If we are able to contact you directly with any queries about
                  this referral, please provide your contact details
                </p>

                {/* Referrer: Email address */}
                <InputText
                  errors={errors.referrerEmail}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="referrerEmail"
                  label="Your email address"
                  name="referrerEmail"
                  touched={touched.referrerEmail}
                  value={values.referrerEmail}
                />

                {/* Referrer: Phone number */}
                <InputText
                  errors={errors.referrerPhoneNumber}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id="referrerPhoneNumber"
                  label="Your phone number"
                  name="referrerPhoneNumber"
                  touched={touched.referrerPhoneNumber}
                  value={values.referrerPhoneNumber}
                />

                {/* ReCAPTCHA */}
                <div
                  className={
                    'u-margin-top-full field-group' +
                    (errors.recaptcha && touched.recaptcha ? ' error' : '')
                  }
                >
                  {errors.recaptcha && touched.recaptcha && (
                    <div className="validation-error">{errors.recaptcha}</div>
                  )}
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={value => {
                      if (value) {
                        setFieldValue('recaptcha', value);
                      } else {
                        setFieldValue('recaptcha', '');
                      }
                    }}
                    ref={this.recaptchaRef}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="u-margin-top-full"
                >
                  Submit
                </button>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default ReferralForm;
