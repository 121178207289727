import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import FlowsLogo from '../images/flows-logo.svg';

const SiteHeader = ({ siteTitle }) => (
  <header className="site-header">
    <div className="site-header__inner container">
      <h1>
        <Link to="/" className="site-header__home-link">
          <FlowsLogo
            className="site-header__logo"
            aria-label="FLOWS - Finding Legal Options for Women Survivors"
          />
        </Link>
      </h1>
      <ul className="site-header__links-list">
        <li className="site-header__list-item">
          <a
            href="https://www.flows.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="site-header__link"
          >
            About FLOWS
          </a>
        </li>
        <li className="site-header__list-item">
          <a
            href="https://www.flows.org.uk/local-services"
            target="_blank"
            rel="noopener noreferrer"
            className="site-header__link"
          >
            Find a local service
          </a>
        </li>
        <li className="site-header__list-item">
          <a
            href="https://www.flows.org.uk/flows-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="site-header__link"
          >
            Privacy policy
          </a>
        </li>
      </ul>
    </div>
  </header>
);

SiteHeader.propTypes = {
  siteTitle: PropTypes.string,
};

SiteHeader.defaultProps = {
  siteTitle: ``,
};

export default SiteHeader;
