import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import FlowsLogo from '../images/flows-logo.svg';

const SiteFooter = ({ siteTitle }) => (
  <footer className="site-footer">
    <div className="container">
      <FlowsLogo
        className="site-footer__logo"
        aria-label="FLOWS - Finding Legal Options for Women Survivors"
      />
      <ul className="site-footer__links-list">
        <li className="site-footer__list-item">
          <a
            href="https://www.flows.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="site-footer__link"
          >
            About FLOWS
          </a>
        </li>
        <li className="site-footer__list-item">
          <a
            href="https://www.flows.org.uk/local-services"
            target="_blank"
            rel="noopener noreferrer"
            className="site-footer__link"
          >
            Find a local service
          </a>
        </li>
        <li className="site-footer__list-item">
          <a
            href="https://www.flows.org.uk/flows-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="site-footer__link"
          >
            Privacy policy
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

SiteFooter.propTypes = {
  siteTitle: PropTypes.string,
};

SiteFooter.defaultProps = {
  siteTitle: ``,
};

export default SiteFooter;
