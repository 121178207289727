import React from 'react';

import RequiredAsterisk from './RequiredAsterisk';

const InputText = ({
  errors,
  handleBlur,
  handleChange,
  hintText,
  id,
  label,
  name,
  rows,
  required,
  touched,
  value,
}) => (
  <div className={'field-group' + (errors && touched ? ' error' : '')}>
    <label htmlFor={id}>
      {label}
      {required && <RequiredAsterisk />}
    </label>
    {hintText && (
      <p className="hint-text" id={`${id}-hint`}>
        {hintText}
      </p>
    )}
    {errors && touched && <div className="validation-error">{errors}</div>}
    <textarea
      name={name}
      id={id}
      rows={rows}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      className={errors && touched ? 'error' : ''}
      aria-describedby={hintText && `${id}-hint`}
    />
  </div>
);

export default InputText;
