import React from 'react';

const RequiredAsterisk = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      className="required-asterisk"
    >
      <path d="M110.1 16.4L75.8 56.8l.3 1 50.6-10.2v32.2l-50.9-8.9-.3 1 34.7 39.1-28.3 16.5-18.2-49.3-.7.3-18.5 49L17.2 111l34.1-39.8v-.6l-50 9.2V47.6l49.3 9.9.3-.6-33.7-40.2L45.5.5l17.8 48.7h.7L82.1.5l28 15.9z" />
    </svg>
    <div className="u-visually-hidden">required</div>
  </>
);

export default RequiredAsterisk;
