import React from 'react';

import RequiredAsterisk from './RequiredAsterisk';

const InputText = ({
  errors,
  handleBlur,
  handleChange,
  hintText,
  id,
  label,
  name,
  touched,
  value,
  required,
  customClass,
}) => {
  let classes = 'field-group';
  const showErrors = errors && touched;

  if (customClass) {
    classes += ` ${customClass}`;
  }

  if (showErrors) {
    classes += ' error';
  }

  return (
    <div className={classes}>
      <label htmlFor={id}>
        {label}
        {required && <RequiredAsterisk />}
      </label>
      {hintText && (
        <p className="hint-text" id={`${id}-hint`}>
          {hintText}
        </p>
      )}
      {showErrors && <div className="validation-error">{errors}</div>}
      <input
        name={name}
        id={id}
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={showErrors ? 'error' : ''}
        aria-describedby={hintText && `${id}-hint`}
      />
    </div>
  )
};

export default InputText;
